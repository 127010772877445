import { Link } from "gatsby";
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Container, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import $ from "jquery";
import GenerateLink from "../common/link/generate-link"
import Seo from "../components/seo"
import "../scss/custom.scss";

const Sitemap = () => {
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        burgerMenus(sort: "Sort:asc") {
            label
            menu {
              slug
              id
            }
            add_menu {
              label
              menu {
                id
                slug
              }
            }
        }
      }
    }
  `);

    const menus = data.glstrapi.burgerMenus;
    return (
        <Layout>
            <Seo title="Sitemap" />
            <div className="news-banner sitemap">
                <Container>
                    <Row>
                        <Col lg={12} className="sitemap-page">
                            <h1>Sitemap</h1>
                            <ul className="sitemap-main-list">
                                {menus && menus.map((item, index) => {
                                    return (
                                        <li className="li-main">
                                            <GenerateLink link={item.menu} class="title">{item.label}</GenerateLink>
                                            <ul className="sub-menu sitemap-sub-menu-list">
                                                {item.add_menu && item.add_menu.length > 0 && item.add_menu.map((submenu) => {
                                                    return <li className='sub-li-list'>
                                                        <GenerateLink link={submenu.menu} class="title">{submenu.label}</GenerateLink>
                                                    </li>
                                                })}
                                            </ul>

                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}


export default Sitemap
